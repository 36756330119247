#tv_background {
    /* background-image: linear-gradient(#665511, #221111, black); */
    height: 100vh;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow: scroll;
    /* border: purple solid 3px; */
    position: relative;
    font-family: creepster, fantasy;
    /* text-shadow: 3px 3px 3px #772222, 5px 5px 0 black; */
    
}

#tvBackgroundImage {
    width: 99%;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: -1;
}

#tvTitleImageRow h1 {
    text-align: center;
    font-weight: bold;
    font-size: 3em;
    color: #ffffff;
    text-shadow: 1px 1px black, 3px 3px red;
    letter-spacing: .75em;
}

p {
    text-shadow: none;
    letter-spacing: normal;
}

#tvTest {
    /* border: purple 3px solid; */
    height: 90vh;
    position: relative;
}

#remote_container > p {
    font-size: 2em;
    padding-bottom: 0;
    width: 50%;
    margin: 0 0 0 40%;
    color: #ffffff;
    text-shadow: 1px 1px black, 3px 3px #772222;
}

#remote_container > h1 {
    /* position: absolute;
    left: 25%; */
    color: #ffffff;
    text-shadow: 1px 1px black, 3px 3px #772222;
}

#tv-guide {
    width: 90%;
    background-color: #3e3e3e4a;
    border: #4b3f30 ridge 5px;
    overflow: scroll;
    height: 55vh;
    margin: 1% auto;
    
    
}

#tv-guide p {
    color: white;
    text-shadow: 1px 1px black, 3px 3px #772222;
}

.tvShowTitles {
    color: green;
    margin-left: 3%;
    text-shadow: 1px 1px black, 2px 2px #772222;
}

.tvShowTitles:hover {
    color: #772222;
    text-shadow: 2px 2px black, 2px 2px green, 0 0 10px white;
    cursor: pointer;
}

#remote {
    width: 90%;
    background-color: #000000;
    text-align: center;
    height: 15vh;
    margin: 2% auto;
    border-radius: 15%;
    box-shadow: 0 0 50px #3e3e3e;
}

#remote p {
    font-size: 2em;
    padding-bottom: 0;
    margin: 0;
    text-shadow: 3px 3px 3px #772222, 5px 5px 0 black;
}

#remote li:nth-of-type(3){
    display: inherit;
}

#remote li,
#remote li:nth-of-type(3){
    display: inline-block;
    list-style-type: none;
    font-size: .6rem;
    margin: 7% 2% 0;
    /* text-align: left; */

    text-shadow: 2px 3px 3px white;
    /* border: 1px solid red; */
}


#remote li:nth-of-type(1),
#remote li:nth-of-type(7) {
    display: none;
}


#largeTvScreen_container {
    /* border: green 3px solid; */
    transform: skewY(8deg);
    width: 50%;
    height: 30vh;
    position: absolute;
    top: 50%;
    right: 30%;
    box-shadow: 0 0 60px #1f0505;
}

.lg-you-tube {
    height: 100%;
    width: 100%;
    opacity: .4;
}

#largeTvScreen {
    position: relative;
    transform: skewY(6deg);
    width: 100%;
    /* min-height: 10%;
    min-width: 10%; */
    z-index: 1;
    bottom: 0;
    opacity: 1;
    /* right: 33%; */
}





#smTvScreen_container {
    transform: skewY(8deg);
    box-shadow: 0 0 50px #353030;
    /* border: orange solid 2px; */
    margin: 10% 0 15%;
    width: 75%;
    height: 20vh;
    /* min-height: 10%;
    min-width: 10%; */
}



    

.smallTvScreen {
    position: relative;
    border: 2px solid yellow;
    width: 100%;
    height: 100%;
    margin: 2% auto;
    z-index: 1;
}







@media screen and (max-width: 450px) {
    .lg-you-tube {
        /* position: absolute; */
        top: -15%;
        /* left: 12%;
        width: 75%;
        min-width: 10%;
        height: 82%;
        z-index: 0;
        margin: 40px auto; */
    }

    #remote {
        text-align: left;
    }

    #remote p {
        font-size: 3.5em;
        padding-bottom: 0;
        margin: 0;
        text-align: center;
    }

    #remote li {
        padding: 0;
    }

    #remote a {
        font-size: 1em;
    }

    ul {
        margin-top: -4%;;
    }

}